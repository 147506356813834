import { Helmet } from "react-helmet";
import { Loader, Segment } from "semantic-ui-react";
import colors from "../utils/colors";
import PageHeader from "./PageHeader";
import StatusBar from "./StatusBar";

const Page = (props) => 
{
	var { 
		header,
		helmet, 
		loading,
		loadingText,
		error,
		success,
		color
	} = props;

	var { hasPermission } = props;

	if (hasPermission === undefined)
	{	
		hasPermission = true;
	}

	if (!color) color = colors.white;

	return (
		<div className={loading ? 'loadingPage' : 'page'} style={{backgroundColor: color}}> 

			<Helmet>
				<title>{helmet}</title>
			</Helmet>

			{loading && 
				<div display='flex' style={{textAlign: 'center'}}>
					<Loader active />
					{loadingText && <h5 style={{marginTop: 20, width: '100%'}}>{loadingText}</h5>}
				</div>
			}

			<div>
				
				{!hasPermission && !loading && 
					<Segment>
						<div style={{margin:10}}>
							<h2 color={colors.accent}>Unauthorized</h2>
							<p style={{color: colors.darkGray}}>Please contact your local admin for elevated priviledges</p>
						</div>
					</Segment>
				}

				{hasPermission && !loading && header && <PageHeader style={{marginBottom: 20}} text={header} />}
				{hasPermission && !loading && (error || success) && <StatusBar style={{marginBottom: 20}} error={error} success={success} />}
				{hasPermission && !loading && props.children}
			</div>

		</div>
	)
}

export default Page;