import colors from "../utils/colors";

export function HiglightedText({text, inverse, maxWidth, fontSize})
{

	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<div style={{fontWeight: 'bold', lineHeight: 1.7, maxWidth: maxWidth ?? 600, fontSize: fontSize ?? 32, textAlign: 'center'}}>
				{text?.map((value, idx) => {
					if (value.highlight)
					{
						return (
							<div style={{
								display: 'inline', 
								padding: 5, 
								paddingLeft: 10,
								paddingRight: 10,
								marginLeft: 10, 
								marginRight: 10,
								color: inverse ? colors.black : colors.white, 
								backgroundColor: inverse ? colors.white : colors.black
								}}>
								{value.text}
							</div>
						);
					}
					else {
						return (
							<div style={{
								display: 'inline', 
								color: inverse ? colors.white : colors.black
							}}>
								{value.text}
							</div>
						)
					}
				})}
			</div>
		</div>
	)
}