const colors = 
{
	pageBackground: '#f0f2f5',
	white: 'white',
	black: 'black',
	primary: '#3d5a80',
	secondary: '#293241',
	accent: '#ee6c4d',
	lightGray: '#f2f2f0',
	lightMidGray: '#ececec',
	midGray: '#A9A9A9',
	darkGray: '#696969',
	limeGreen: '#c9f801'
}

export default colors;