import '../App.css';
import React from 'react';
import withRouter from '../utils/withRouter';
import { Container, Segment } from 'semantic-ui-react'
import colors from '../utils/colors';
import config from '../utils/config';
import Page from '../components/Page';
import fbApp from '../utils/firebase';
import firebase from 'firebase/compat/app';
import StyledFirebaseAuth from '../components/StyledFirebaseAuth';
import ReactGA from 'react-ga4';

class LoginPage extends React.Component {

  constructor(props) 
  {
    super(props);
    this.state = {
      error: null,
      loading: true,
      uiConfig: {
        signInOptions: [
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
        ],
        // signInFlow: 'redirect',
        signInFlow: 'popup',
        callbacks: {
          signInSuccessWithAuthResult: (res, path) => 
          {
            config.debug && console.debug("SUCCESS PATH: ",res, path);
  
            if (this.props.router?.location?.state?.prevPathname)
            {
              var pathname = this.props.router?.location?.state?.prevPathname;
              var search = this.props.router?.location?.state?.prevSearch;
  
              window.location.assign(`${pathname}${search}`)
              // do not redirect from firebase auth..
              return false;
            }
  
            // continue to redirect..
            return true;
          },
          signInFailure: (err) => 
          {
            console.error("Failed to sign in", err);
            this.props.router.navigate("/");  
          }
        }
      },
    }
  }

  componentDidMount = () => 
	{
		ReactGA.send('pageview');
	}

  render() 
  {
    const { error, uiConfig} = this.state;

    return (
      <Page 
        helmet={`Login - Scheduly`}
        error={error}
        color='#f0f2f5'
      >
        <Container style={{textAlign: 'center', paddingTop: 50}}>

          <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>

            <Segment style={{maxWidth: 350}}> 

              <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={fbApp.auth()} />
              <p style={{fontSize: 12, padding: 10, color: colors.darkGray}}>By continuing, you agree to Scheduly's Conditions of Use and Privacy Notice. </p>

            </Segment>

          </div>
        
        </Container>

      </Page>
    )
  }
}

export default withRouter(LoginPage);