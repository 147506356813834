import '../App.css';
import React from 'react';
import withRouter from '../utils/withRouter';
import { Container, Divider, Form, Image } from 'semantic-ui-react'
import firebase from '../utils/firebase';
import MyButton from '../components/MyButton';
import Page from '../components/Page';
import UserDialog from '../components/UserDialog';
import config from '../utils/config';
import api from '../utils/api';
import DateUtils from '../utils/DateUtils';
import { format, isValid} from 'date-fns';
import colors from '../utils/colors';
import logo from '../content/calendar-512.png';
import ReactGA from 'react-ga4';

class AccountsPage extends React.Component {

  constructor(props) 
  {
    super(props);
    this.state = {
      error: null,
      loading: false,
      visible: false,
      deleteLoading: false,
      openDeleteDialog: false,
      subscriptions: null,
    }
  }

  componentDidMount = async () => 
  {
    ReactGA.send('pageview');
    
    this.setState({loading:true}, async () => 
    {
      const subscriptions = await api.getSubscriptions();
      this.setState({loading: false, subscriptions});
    }); 
  }

 	signOutUser = () => 
	{
		firebase.auth().signOut()
      .then(() => console.log('Logged out'))
      .catch((error) => console.error(error));
	}

  render() 
  {
    const { 
      loading,
      subscriptions
    } = this.state;

    const { 
      user,
    } = this.props;

    return (
      <Page 
        helmet={`Account - Scheduly`}
        loading={loading}
      > 

        <Container style={{padding: 30}}>
          <div>
            <div>MY ACCOUNT</div>
            <Divider/>
            <div style={{marginTop: 5, fontWeight: 'bold', fontSize: '1.2em'}}>{user?.email}</div>
          </div>
          
          <div style={{marginBottom: 50, marginTop: 50}}>
            <div>CURRENT PLAN{subscriptions?.length > 1 && 'S'}</div>
            <Divider/>

            {subscriptions?.map((sub, idx) => {
              var endDt = DateUtils.GetLocalDateFromUnixEpoch(sub.current_period_end);
              var endedStr = format(endDt, ' MMMM do, yyyy')
              var canceled = sub.status === 'canceled';

              var isCanceled = sub.cancel_at_period_end || sub.cancel_at;
              var cancelDt = DateUtils.GetLocalDateFromUnixEpoch(sub.cancel_at);
              var cancelStr = isValid(cancelDt) ? format(endDt, ' MMMM do, yyyy') : '';
              var active = sub.status === 'active';

              var trialing = sub.status === 'trialing';
              var trialEndDt = DateUtils.GetLocalDateFromUnixEpoch(sub.trial_end);
              var trialEndStr = isValid(trialEndDt) ? format(trialEndDt, ' MMMM do, yyyy') : '';

              return (
                <div key={idx} style={{display: 'flex', opacity: canceled ? 0.3 : 1, marginBottom: 20}}>
                  <div style={{height:60, width:60}}>
                    <Image src={logo}/>
                    </div>
                  
                  <div key={idx} style={{margin: 10}}>

                    <div style={{fontSize: '1.3em', fontWeight: 'bold'}}>{canceled ? '[ Canceled ]' : ''} {sub.metadata?.site_name} Subscription</div>
                    <div style={{marginTop: 8, fontSize: '1.3em'}}>${sub.plan?.amount/100}.00 per {sub.plan?.interval}</div>

                    {active && !isCanceled && <div style={{marginTop: 5, color: colors.darkGray}}>Your plan renews on {endedStr}.</div>}
                    {active && isCanceled && <div style={{marginTop: 5, color: colors.darkGray}}>Your plan will be canceled on {cancelStr}.</div>}
                    {trialing && !isCanceled && <div style={{marginTop: 5, color: colors.darkGray}}>After your free trial ends on {trialEndStr}, this plan will continue automatically.</div>}
                    {trialing && isCanceled && <div style={{marginTop: 5, color: colors.darkGray}}>After your free trial ends on {trialEndStr}, this plan will no longer be available.</div>}

                    <div style={{display: 'flex', marginTop: 10,}}>
                      <form action={`${config.endpoint}/create-customer-portal-session`} method='POST'>
                        <input
                          type='hidden'
                          id='return_url'
                          name='return_url'
                          value={window.location.href}
                        />
                        <input
                          type='hidden'
                          id='user_token'
                          name='user_token'
                          value={user?._delegate?.accessToken}
                        />
                        <input
                          type='hidden'
                          name='customer_id'
                          value={sub.customer}
                        />
                        <button style={{marginRight: 15, fontSize: '1em'}} type='submit'>{canceled ? 'View Plan' :'Manage Plan'}</button>
                      </form>

                      {!canceled && <div style={{fontSize: '1em'}}><a href={`https://${sub.metadata?.subdomain}.scheduly.org`}>Visit website</a></div>}

                    </div>

                  </div>
                </div>
              );
            })}

            {subscriptions?.length === 0 && 
              <div style={{color: colors.darkGray}}>You do not have a current plan. <a href={`${window.location.origin}/plans`}>View plans</a></div>
            }
         </div>

          <Form.Field>
            <MyButton 
              style={{marginTop: 15}}
              label={'Sign Out'}  
              onClick={() => this.signOutUser()}
            />
          </Form.Field>

          {config.debug && 
            <Form.Field>
              <MyButton 
                label={'Delete Account'} 
                secondary 
                onClick={() => this.setState({openDeleteDialog: true})} 
              />
            </Form.Field>
          }

          <UserDialog 
            header='Warning'
            body='Delete action cannot be undone. Are you sure you want to continue?'
            submitBtnText='Delete'
            submitBtnNegative
            open={this.state.openDeleteDialog}
            onCancel={() => this.setState({openDeleteDialog:false})}
            onSubmit={() => user.delete()}
          />
        </Container>
      </Page>
    );
  }
}

export default withRouter(AccountsPage);