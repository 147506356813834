import { Container, Image, List, Segment } from "semantic-ui-react";
import colors from "../utils/colors";
import logo from '../content/calendar-192.png';

export default function Footer(props)
{
	return (
		<div 
			style={{
				width: '100%', 
				color: colors.white,
				fontSize: '1.4em',
			}}
		>
			<Segment inverted vertical style={{ margin: 0, padding: 50 }}>
				<Container textAlign='center'>
					<Image centered size='mini' src={logo} />
					<List horizontal inverted divided link size='small'>
						<List.Item as='a' href='#'>
							Site Map
						</List.Item>
						<List.Item as='a' href='#'>
							Contact Us
						</List.Item>
						<List.Item as='a' href='#'>
							Terms and Conditions
						</List.Item>
						<List.Item as='a' href='#'>
							Privacy Policy
						</List.Item>
					</List>
				</Container>
    	</Segment>
		</div>
	);
}
