import React from 'react';
import { Header, TextArea } from 'semantic-ui-react';
import Modal from 'react-modal';
import MyButton from './MyButton';

export default class UserDialog extends React.Component
{
	constructor(props){
		super(props);
		this.state = {
			notes: null,
		}
	}

	render() 
	{
		const { 
			header, 
			body, 
			input, 
			open,  
			submitBtnText,
			onSubmit, 
			onCancel 
		} = this.props;

		return (
			<Modal
				isOpen={open}
				style={{
					content: {marginTop: 200, height: '100%', maxHeight: 240},
					overlay: {}
				}}
				contentLabel={header}
			>
				{header && <Header>{header}</Header>}

				{body && <p>{body}</p>}

				{input && 
					<TextArea 
						style={{width:'100%', minHeight:50}} 
						value={this.state.notes} 
						onChange={(e) => this.setState({notes: e.target.value})} 
					/>}
				
				<div style={{marginTop: 20}}>

				<MyButton 
					label={submitBtnText ? submitBtnText : 'Confirm'}
					secondary
					onClick={() => 
						{
							onCancel();
							onSubmit(this.state.notes);
						}}
				/>

				<MyButton 
					label='Cancel'
					primary 
					style={{marginTop: 10}}
					onClick={onCancel}
				/>

				</div>
			</Modal>
		)
	}
}