import { Message } from 'semantic-ui-react';

export default function StatusBar(props)
{
	const { style, error, errorHeader, success, successHeader, components, color } = props;

	return (
		<div style={style}>
			{error != null && 
				<Message negative color={color}>
					<Message.Header>{errorHeader ? errorHeader : 'Error'}</Message.Header> 
					<p>{error}</p>
					{components !== undefined && components()}
				</Message>
			}

      {success != null &&
				<Message positive>
					<Message.Header>{successHeader ? successHeader : 'Success'}</Message.Header> 
					<p>{success}</p>
				</Message>
			}

		</div>
	)
}