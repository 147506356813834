const DEBUG = false;

const config = 
{
	endpoint: process.env.REACT_APP_FUNCTIONS_ENDPOINT,
	product_id_clubhouse: process.env.REACT_APP_PRODUCT_ID_CLUBHOUSE,
	product_id_startup: process.env.REACT_APP_PRODUCT_ID_STARTUP,
	product_id_company: process.env.REACT_APP_PRODUCT_ID_COMPANY,
	product_id_enterprise: process.env.REACT_APP_PRODUCT_ID_ENTERPRISE,
	debug: DEBUG,
	analyticsMeasurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

export default config;