import { useState } from "react";
import { Button } from "semantic-ui-react";
import colors from "../utils/colors";

export default function MyButton(props)
{
	const { 
		color,
		onClick,
		label,
		loading,
		disabled,
		primary,
		secondary,
		style,
		type
	} = props;

	var [hovered, setHovered] = useState(false);

	var allStyles = { borderRadius: 35, height: 45 };
	allStyles.color = hovered ? colors.lightGray : colors.white;

	if (primary) {
		allStyles.backgroundColor = hovered ? colors.darkGray : colors.primary;
	}
	else if (secondary) {
		allStyles.backgroundColor = hovered ? colors.darkGray : colors.secondary;
	} 
	else
	{
		allStyles.color = hovered ? colors.darkGray : colors.darkGray;
	}


	if (color) {
		allStyles.backgroundColor = hovered ? colors.darkGray : color;
	}
	
	if (style)
	{
		Object.keys(style).forEach((key) => {
			allStyles[key] = style[key];
		})
	}

	return (
		<div 
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
		<Button 
			fluid
			loading={loading}
			disabled={disabled}
			style={allStyles} 
			onClick={onClick}
			type={type}
		>
			{label}
		</Button>
		</div>
	)
}