import config from './config';
import firebase from '../utils/firebase';

export default class api
{
	static async getUser()
	{
		var user = await firebase.auth();
    return user.currentUser;
  }

	static async getLatestUserToken()
	{
		var user = await firebase.auth();
		console.log('user', user)
		var userIdToken = await user.currentUser?.getIdToken();
    return userIdToken;
  }

  static async getRequestHeaders()  
  {
    let headers = new Headers();
    let token = await this.getLatestUserToken();
    headers.set('Access-Control-Allow-Origin', '*');
    headers.set('Content-Type', 'application/json');
    headers.set('Authorization', token);
    return headers;
  }

	static getSubscriptions()
	{
		return new Promise(async (resolve, reject) => {
			fetch(`${config.endpoint}/customer-subscriptions`, {
				method: 'GET',
				headers: await this.getRequestHeaders(),
			})
			.then(async (res) => {
        if (res.ok)
        {
          var data = await res.json();
					config.debug && console.debug(`GET Subscriptions`, data);
					resolve(data);
        }
        else 
        {
          resolve([]);
        }
      })
			.catch(err => 
				{
					console.error(err);
					reject(err)
				});
		});
	}

	static getRegisteredSites()
	{
		return new Promise(async (resolve, reject) => {
			fetch(`${config.endpoint}/registered-sites`, {
				method: 'GET',
				headers: await this.getRequestHeaders(),
			})
			.then(async (res) => {
        if (res.ok)
        {
          var data = await res.json();
					config.debug && console.debug(`GET registered sites`, data);
					resolve(data);
        }
        else 
        {
          resolve(null)
        }
      })
			.catch(err => reject(err));
		});
	}
}