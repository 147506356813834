import '../App.css';
import React from 'react';
import Page from '../components/Page';
import { Button, Card, Container, Divider, Grid, Icon } from 'semantic-ui-react';
import withRouter from '../utils/withRouter';
import colors from '../utils/colors';
import ReactGA from 'react-ga4';

const PLANS = [
	{
		name: 'Clubhouse',
		price: 19.99,
		smsCount: 600,
		emailCount: '1,000',
		btnText: 'Start 30-Day Trial',
		color: 'green',
		icon: 'leaf',
		templatesCount: 5,
		adminCount: 1,
	},
	{
		name: 'Startup',
		price: 39.99,
		smsCount: '1,300',
		emailCount: '2,500',
		btnText: 'Checkout',
		color: 'brown',
		icon: 'child',
		templatesCount: 10,
		adminCount: 3,
	},
	{
		name: 'Company',
		price: 59.99,
		smsCount: '2,100',
		emailCount: '4,000',
		btnText: 'Checkout',
		color: colors.secondary,
		icon: 'users',
		templatesCount: 20,
		adminCount: 10,
	},
	{
		name: 'Enterprise',
		price: 79.99,
		smsCount: '3,000',
		emailCount: '6,000',
		btnText: 'Checkout',
		color: 'purple',
		icon: 'building',
		templatesCount: 'Unlimited',
		adminCount: 'Unlimited',
	},
	// {
	// 	name: 'Platinum',
	// 	price: 80,
	// 	smsCount: 2500,
	// 	btnText: 'Checkout'
	// }
];

class PlansPage extends React.Component {

  constructor(props) 
  {
    super(props);
    this.state = {
      error: null,
      loading: false,
      btnCheckoutLoading: false,
    }

		this.topOfPage = React.createRef(); 
  }

	componentDidMount = () => 
	{
		ReactGA.send('pageview');

		this.topOfPage?.current.scrollIntoView({behavior: 'smooth'});
	}


  render() 
  {
		const faq = [
			{ 
				question: "Is payment monthly or yearly?",
				answer: "You'll receive an invoice once a month and can cancel the plan at anytime."
			},
			{ 
				question: "When does my subscription expire?",
				answer: "You can go to the 'My Account' page to view all your current plan details."
			},
			{ 
				question: "How do SMS and Email Credits work?",
				answer: "Anytime you send out a notification via SMS or Email, the associated credit will be deducted. Your monthly credit limit will reset at the start of every billing period. If you exceed the Credit limit within the billing period, you will be charged an overdraft fee."
			},
			{ 
				question: "What are SMS Segments?",
				answer: "SMS credits represent how many SMS segments you are allowed to send. Think of segments as chunks - the longer the SMS message, the more segments. If you send a very long SMS, it may cost you 2 or even 3 segments (depending on the length of msg and encoding). To read more https://www.twilio.com/blog/2017/03/what-the-heck-is-a-segment.html"
			},
			{ 
				question: "Do SMS & Email Credits rollover?",
				answer: "Half of your credits will automatically rollover to the next billing period. For example, if you're on the most basic plan (600 SMS segments / month) and you only used 200 credits that period, 400 SMS credits would be remaining. That would mean 300 SMS credits would rollover to the next billing period, in addition to your plan's monthly SMS credit limit. So, you would have a total of 900 SMS credits at the beginning of the next billing period."
			},
			{ 
				question: "What if I go over my Credit limit?",
				answer: "You will be charged an overdraft fee and it will appear on your next invoice. The SMS overdraft fee is $5.00 and it will credit you an additional 125 SMS credits. The Email overdraft fee is $1.00 and will credit you an additional 100 Email credits."
			},
			{ 
				question: "Can I cancel my plan at any time?",
				answer: "Yes! If you have an existing subscription, you can go to 'My Account' to manage your subscription."
			},
			{ 
				question: "Can I disable Credit overdrafts?",
				answer: "It's enabled by default, but you can disable it. We strongly advise against disabling the overdraft because you probably wouldn't want any of your notifications to fail to deliver."
			},
		];

    return (
			<div ref={this.topOfPage}>
      <Page 
        helmet={`Plans - Scheduly`}
      > 
				<div style={{backgroundColor: colors.pageBackground, paddingTop: 50, paddingBottom: 100}}>

				<div style={{width: '100%',textAlign: 'center', fontWeight: 'bold', fontSize: 26, color: colors.black}}>
					Plans and Pricing
				</div>

				<div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
					<Grid basic stackable style={{padding: 50, maxWidth: 1400}}>
						<Grid.Row>

							{PLANS.map((plan, idx) => 
								<Grid.Column width={4}>
									<div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
										<Card>
											<Card.Content>
												<Card.Header >
													<div style={{padding: 10, paddingTop: 0}}>
														<div style={{display: 'flex', marginTop: 10, marginBottom: 0, color: plan.color}}>
															<div style={{fontSize: 26, marginRight: 10}}>{plan.name}</div>
															<Icon name={plan.icon} style={{color: plan.color}}/>
														</div>
														<div style={{display: 'flex', marginTop: 15}}>
															<div style={{fontSize: 24, margin: 6, marginLeft: 0}}>${plan.price}</div>
															<div style={{fontSize: '0.8em', lineHeight: 2, margin: 5, marginLeft: 0, color: colors.darkGray, fontWeight: 200, fontStyle: 'italic'}}>/ month</div>
														</div>
													</div>
													<Divider/>
												</Card.Header>

												<Card.Description>
													<div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
														<div style={{margin: 10, marginTop: 0, fontSize: '1.1em'}}>
															<div style={{margin: 10}}><Icon name='server' color={'gray'}/> Website Hosting</div>
															
															<div style={{ margin: 10 }}><Icon name='code' color='gray' /> Event Automation</div>
															<div style={{ margin: 10 }}><Icon name='chart line' color='gray' /> Event Reports</div>

															<div style={{ margin: 10 }}><Icon name='calendar alternate' color='gray' /> {plan.templatesCount} Event Templates</div>

															<div style={{ margin: 10 }}><Icon name='calendar check' color='gray'/> Unlimited Events</div>
															<div style={{ margin: 10 }}><Icon name='users' color='gray'/> Unlimited Users</div>
															
															<div style={{ margin: 10 }}><Icon name='chat' color='gray'/> {plan.smsCount} SMS / month</div>
															<div style={{ margin: 10 }}><Icon name='mail' color='gray'/> {plan.emailCount} Email / month</div>
															<div style={{ margin: 10 }}><Icon name='id badge' color='gray'/> {plan.adminCount} Admin Accounts</div>

															{/* {idx > 1 && <div style={{margin: 10}}><Icon name='check' color='green'/> Priority Support</div>} */}
														
														</div>
													</div>

													<div style={{margin: 15, marginTop: 25, fontSize: '.9em'}}>
														<Button
															secondary
															fluid
															type='submit' 
															circular
															onClick={()=> this.props.router.navigate(`/checkout?plan=${plan.name}`)}
														>
															{plan.btnText}
														</Button>
													</div>

												</Card.Description>
											</Card.Content>
										</Card>
									</div>
								</Grid.Column>
							)}

						</Grid.Row>
					</Grid>
					
				</div>

				</div>

				<Container style={{marginTop: 20, backgroundColor: 'white', padding: 40, paddingBottom: 80}}>
					<div style={{fontSize: '2em', fontWeight: 'bold'}}>FAQ</div>

					<Divider/>

					{faq?.map((f, idx) => {
						return (
							<div key={idx} style={{marginTop: 30}}>
								<div style={{fontSize: '1.5em', fontWeight: 'bold', paddingBottom: 20}}>{f.question}</div>
								<div style={{fontSize: '1.2em'}}>{f.answer}</div>
							</div>
						)
					})}
					
				</Container>

      </Page>
			</div>
    );
  }
}

export default withRouter(PlansPage)