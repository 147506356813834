import '../App.css';
import React from 'react';
import withRouter from '../utils/withRouter';
import Page from '../components/Page';
import MyButton from '../components/MyButton';
import config from '../utils/config';
import { Container, Divider, Form, Header, Input, Segment } from 'semantic-ui-react';
import InputLabel from '../components/InputLabel';
import colors from '../utils/colors';
import api from '../utils/api';
import queryString from 'query-string';
import ReactGA from 'react-ga4';

class CheckoutPage extends React.Component {

  constructor(props) 
  {
    super(props);
    this.state = {
      error: null,
      loading: true,
      btnCheckoutLoading: false,
      subdomain: '',
      siteName: '',
      registeredSubdomains: [],
      subdomainValid: false,
      siteNameValid: false,
      subdomainError: null,
      productId: null,
      planName: null,
    }
  }

  componentDidMount = async () => 
  {
    ReactGA.send('pageview');
    
    var params = queryString.parse(this.props.router.location.search);
    const planName = params.plan;

    var productId = null;
    switch(planName)
    {
      case 'Clubhouse':
        productId = config.product_id_clubhouse
        break;
      case 'Startup':
        productId = config.product_id_startup
        break;
      case 'Company':
        productId = config.product_id_company
        break;
      case 'Enterprise':
        productId = config.product_id_enterprise
        break;
      default:
        break;
    }

    const registeredSubdomains = await api.getRegisteredSites();

    this.setState({
      loading: false,
      registeredSubdomains, 
      productId, 
      planName, 
      error: productId ? null : 'Cannot checkout. Plan is not selected'
    });
  }

  handleSubmit = (e) => 
  {
    const { subdomain, siteName } = this.state;

    this.setState({btnCheckoutLoading: true}, () => 
    {
      if (!siteName || !subdomain) 
      {
        e.preventDefault();
        this.setState({btnCheckoutLoading: false})
        return;
      }
    });
  }

  handleSubdomainChange = (input) =>
  {
    var subdomain = input.trim().replace(' ', '');
    if (this.state.registeredSubdomains.includes(subdomain.toLocaleLowerCase()))
    {
      this.setState({subdomain, subdomainValid: false, subdomainError: { content: 'Subdomain is already in use'}})
      return;
    }
    this.setState({subdomain, subdomainValid: true, subdomainError: null});
  }

  handleSitenameChange = (input) =>
  {
    var siteName = input;
    if (!siteName)
    {
      this.setState({siteName, siteNameValid: false})
      return;
    }
    this.setState({siteName: siteName, siteNameValid: true});
  }

  render() 
  {
    const { 
      btnCheckoutLoading,
      subdomain,
      siteName,
      subdomainValid,
      siteNameValid,
      subdomainError,
      productId,
      error,
      planName,
      loading
    } = this.state;

    const {  
      user
    } = this.props;
    return (

      <Page 
        // header={'Checkout'}
        error={error}
        helmet={`Checkout - Scheduly`}
        loading={loading}
      > 
        <Container style={{justifyContent: 'center', padding: 30}}>
          {productId &&
            <Form action={`${config.endpoint}/create-checkout-session`} method="POST" onSubmit={this.handleSubmit}>

              {/* Add a hidden field with the lookup_key of your Price */}
              <input type="hidden" name="product_id" value={productId} />
              <input type="hidden" name="user_token" value={user?._delegate?.accessToken} />

              <Header>{planName} Plan</Header>
              <Divider/>

              <Form.Field>
                <InputLabel text='Organization Name' />
                <input 
                  placeholder='Ex. LifeWay' 
                  name='siteName'
                  value={siteName} 
                  onChange={(e) => this.handleSitenameChange(e.target.value)} 
                />
              </Form.Field>

              <Form.Field 
                control={Input}
                label='Sub-domain'
                name='subdomain'
                value={subdomain}
                onChange={(e) => this.handleSubdomainChange(e.target.value)} 
                placeholder='name.scheduly.org' 
                error={subdomainError}
              />

              {subdomain.length > 0 && 
                <Segment style={{color: colors.white, backgroundColor: colors.secondary, fontFamily: 'monospace', marginTop: 40}}>
                  {siteName}: https://{subdomain?.toLocaleLowerCase().trim()}.scheduly.org
                </Segment>
              }

              <Form.Field>
                <MyButton 
                  label={`Checkout`} 
                  primary
                  disabled={!subdomainValid || !siteNameValid}
                  onClick={()=> this.setState({btnCheckoutLoading: true})}
                  loading={btnCheckoutLoading} 
                  type='submit'
                  style={{marginTop: 50}}
                />
              </Form.Field>

              <Form.Field>
                <MyButton 
                  label='Return to Plans' 
                  onClick={()=> this.props.router.navigate('/plans')} 
                  style={{marginTop: 15}}
                />
              </Form.Field>
            </Form>
            }
        </Container>
        
      </Page>
    );
  }
}
export default withRouter(CheckoutPage);