import '../App.css';
import React from 'react';
import withRouter from '../utils/withRouter';
import { Button, Card, Grid, Icon, Image } from 'semantic-ui-react'
import Page from '../components/Page';
import colors from '../utils/colors';
import {isMobile} from 'react-device-detect';
import ReactGA from 'react-ga4';
import { HiglightedText } from '../components/HighlightedText';
import laptop_dashboard from '../content/laptop_dashboard.png';
import laptop_groups from '../content/laptop_groups.png';
import laptop_home from '../content/laptop_home.png';
import laptop_staff from '../content/laptop_staff.png';
import laptop_planner from '../content/laptop_planner.png';
import laptop_reports from '../content/laptop_reports.png';
import iphone_members from '../content/iphone_members.png';
import phone_alerts from '../content/phone_alerts.png';
import phone_profile from '../content/phone_profile.png';
import phone_event from '../content/phone_event.png';
import phone_sms from '../content/phone_sms.png';

const testimonies = [
	{
		name: 'Yaroslav Narovchenko',
		role: 'Senior Pastor at LifeWay Church',
		avatar: require('../content/avatars/yaroslav.png'),
		text: `We use Scheduly to plan all of our Sunday services and share it with the rest of the team.
		\nThis allows everyone to be on the same page and to have a central repository of information.`,
	},
	{
		name: 'Leonid Kolesnik',
		role: 'Deacon at LifeWay Church',
		avatar: require('../content/avatars/leonid.png'),
		text: `The Greeters and Ushers team use Scheduly to automatically find coverage with smart rotations.`,
	},
	{
		name: 'Eugene Yakovlev',
		role: 'Young Adults Pastor at LifeWay Church',
		avatar: require('../content/avatars/eugene.png'),
		text: `Scheduly allows us to manage the people in our small group and to track their attendance.
		\nThis helps us know which members we need to follow up with.`,
	},
]
const sections = [
	{ 
		title: `Shared Calendar`,
		body: `The shared calendar helps you share events with all of your congregants and staff members.
			\nSee upcoming events, worship services, and meetings all-in-one place.`,
		img: laptop_home,
		desktopImg: true
	},
	{
		title: `Event Planning`,
		body: `Events allow you to schedule volunteers, create worship plans, and delegate responsibilities.
		\nSend out invitations to events so that you'll know who will be there.`,
		img: phone_event,
	},
	{
		title: `Powerful Automation`,
		body: `Use smart rotations to automatically schedule and notify members for future events.
			\nSet up a plan to automate the creation of events, reminding members, and to find coverage.`,
		img: laptop_planner,
		desktopImg: true
	},
	{
		title: `Member Directory`,
		body: `Organize the personal details of individual members in a shared repository for your team.`,
		img: iphone_members
	},
	{
		title: `Built-In Reports`,
		body: `Gain powerful insights by tracking event attendance history and patterns.\n\n`,
		img: laptop_reports,
		desktopImg: true
	},
	{
		title: `Member Profiles`,
		body: `Individual members can view all of their scheduled requests and confirm their attendance.`,
		img: phone_profile
	},

	{
		title: `Event Templates`,
		body: `Create unique plans for each service, including details about the schedule, staff, and those that will be attending.
			\nTemplates allow you to share information across events, without needing to recreate them from scratch.`,
		img: laptop_staff,
		desktopImg: true
	},
	{ 
		title: `SMS & Emails Alerts`,
		body: `Send SMS or Email alerts to notify your congregants or staff about an event.
			\n *Does not require signing into or installing an app to respond to alerts*`,
		img: phone_sms,
	},
	{
		title: `Groups`,
		body: `Create groups of all kinds to organize people and create communities.
			\nUseful for tracking different ministries, committees, or small groups.
		`,
		img: laptop_groups,
		desktopImg: true
	},
	// { 
	// 	title: `Calendar Search`,
	// 	body: `Search across all of your events to find exactly what you're looking for and analysis.`,
	// 	img: laptop_search,
	// 	desktopImg: true
	// },
	{ 
		title: `Mass Alerts`,
		body: `Send mass alerts to notify all of your members of an important annoucement.
			\nFor example, "Church service will be delayed by 2 hrs due to snow storm."`,
		img: phone_alerts,
	},
	{ 
		title: `Admin Dashboard`,
		body: `View the dashboard to see all of your website activity at a glance.`,
		img: laptop_dashboard,
		desktopImg: true
	}
]

class HomePage extends React.Component 
{
	constructor(props)
	{
		super(props)
		this.state = {
			error: null
		}

		this.featuresSection = React.createRef(); 
	}

	componentDidMount = () => 
	{
		ReactGA.send('pageview');
	}

  render() 
  {
    const { error } = this.state;

    return (
      <Page 
        helmet={`Home - Scheduly`}
        error={error}
      >
				
				<div style={{ 
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					backgroundColor: colors.pageBackground, 
					paddingTop: 40, 
					textAlign: 'center', 
					color: 'white'
				}}>
					<div style={{margin: 40}}>
						<HiglightedText 
							maxWidth={600} 
							fontSize={32} 
							text={[
								{ text: `Scheduly:`, highlight: true },
								{ text: `church `, highlight: false},
								{ text: `scheduling `, highlight: false },
								{ text: `& planning software`, highlight: false },
							]}
							/>
					</div>
						
				{/* <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 10}}>
					<div style={{fontSize: 16, lineHeight: 1.3, color: colors.darkGray, maxWidth: 350}}>
						<div style={{display: 'inline'}}>did you know that every church has events, but not every church has a shared calendar? </div>
					</div>
				</div> */}

				{/* <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 40}}>
					<div
						style={{
							position: "relative",
							overflow: 'hidden',
							width: '100%' ,
							paddingBottom: '56.25%',
							height: '100%',
						}}
					>
							<iframe 
								style={{
									position: "absolute",
									top: 0,
									left: 0,
									width: "100%",
									height: "100%",
								}}
								src="https://www.youtube.com/embed/KD81gEnJpT8"
								title='Scheduly - Church Calendar Software' 
								frameborder="0" 
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
								allowfullscreen>
							</iframe>
						</div>
				</div> */}

				<div style={{width: '100%', display: 'flex', justifyContent: 'center', paddingTop: 50}}>
					<Button 
						style={{minWidth: 200, maxWidth: 300, height: 50, backgroundColor: colors.accent}}
						secondary
						fluid
						circular
						onClick={() => this.featuresSection?.current.scrollIntoView({behavior: 'smooth'})}
					>
						See Features
					</Button>
				</div>

				<div style={{color: colors.black, paddingRight: 50, paddingLeft: 50, paddingBottom: 80, paddingTop: 40}}>
					<div style={{display: 'inline-block', padding: 5}}>
						<Icon name='check'/>Available for Mobile & Desktop
						</div>
						<div style={{ display: 'inline-block', padding: 5 }}>
							<Icon name='check' />100% money-back guarantee
						</div>
				</div>
			</div>
	
			<div style={{backgroundColor: colors.black, paddingTop: 40, paddingBottom: 40}}>
				<div style={{margin: 40}}>
					<HiglightedText 
						maxWidth={600}
						fontSize={24} 
						inverse
						text={[
							{ text: `Scheduly can help you`, highlight: false},
							{ text: `automate`, highlight: true},
							{ text: `your church calendar`, highlight: false},
						]}
					/>
				</div>
			</div>
			
			<div ref={this.featuresSection}>
				{sections?.map((section, idx) => 
				{
					const backgroundColor = idx % 2 === 0 ? '#f8fbfe' : '#f0f2f5';
					// for mobile view we always want title to go before img.
					// for desktop, alternate the view..
					const leftToRight = isMobile || idx % 2 === 0;

					return (
						<Section 
							key={idx}
							title={section.title}
							body={section.body}
							desktopImg={section.desktopImg}
							img={section.img}
							backgroundColor={backgroundColor} 
							leftToRight={leftToRight}
						/>
					);
				})}
			</div>

			<div style={{backgroundColor: colors.black, paddingTop: 40, paddingBottom: 40}}>
				<div style={{margin: 40}}>
					<HiglightedText 
						maxWidth={600} 
						fontSize={24} 
						inverse
						text={[
							{ text: `What are`, highlight: false},
							{ text: `churches`, highlight: true},
							{ text: `using Scheduly saying?`, highlight: false},
						]}
						/>
				</div>
			</div>

			<div style={{paddingTop: 50, paddingBottom: 50, display: 'flex', overflowX: 'auto'}} >
				{testimonies?.map((testimony, idx) => 
				{
					return (
							<Card raised  link style={{minWidth: 400, marginLeft: 30}}>
								<div style={{padding: 20,}}>
									<div style={{display: 'flex'}}>
										<Image avatar src={testimony.avatar} style={{width: 50, height: 50}}/>
										<div style={{marginLeft: 10}}>
											<div style={{fontWeight: 'bold', fontSize: 16}}>
												{testimony.name}
											</div>
											<div style={{color: colors.darkGray,}}>
												{testimony.role}
											</div>
										</div>
									</div>
									
									<div style={{color: colors.darkGray, fontSize: 16, marginTop: 10, fontStyle: 'italic', whiteSpace: 'pre-line'}}>
										{testimony.text}
									</div>
								</div>
								
							</Card>
					)
				})}
				</div>
			<div style={{marginTop: 50, marginBottom: 50}}>
				
				
			</div>

			<div style={{backgroundColor: colors.black, paddingTop: 40, paddingBottom: 40}}>
				<div style={{margin: 40}}>
					<HiglightedText 
						maxWidth={600} 
						fontSize={24} 
						inverse
						text={[
							{ text: `If your church doesn't benefit from our software, you can have your money back`, highlight: false},
							{ text: `100% guarantee`, highlight: true},
						]}
						/>
				</div>
			</div>

			{/* <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 100}}>
				<div style={{fontSize: 16, lineHeight: 1.3, color: colors.darkGray, maxWidth: 500, textAlign: 'center'}}>
					<div style={{display: 'inline'}}>Still not convinced? Tour our demo website to see it in action </div>
				</div>
			</div>

			<div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
				<div>
					<Button 
						style={{minWidth: 250, marginTop: 30, marginBottom: 20, height: 50}}
						fluid
						secondary
						circular
						onClick={() => window.location.assign('https://demo.scheduly.org')}
					>
						Visit Demo
					</Button>
				</div>
			</div> */}
{/* 
			<div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 0}}>
				<div style={{fontSize: 16, lineHeight: 1.3, color: colors.darkGray, maxWidth: 500, textAlign: 'center'}}>
					<div style={{display: 'inline'}}>or</div>
				</div>
			</div> */}

			<div style={{width: '100%', display: 'flex', justifyContent: 'center', paddingTop: 80, paddingBottom: 100}}>
				<Button 
					style={{minWidth: 200, maxWidth: 300, height: 50}}
					secondary
					fluid
					circular
					onClick={() => this.props.router.navigate('/plans')}
				>
					See Plans & Pricing
				</Button>
			</div>
		</Page>
    )
  }
}

const Section = (props) => 
{
	const {
		title,
		body, 
		backgroundColor, 
		img, 
		leftToRight, 
		desktopImg
	} = props;

	return (
		<div style={{backgroundColor: backgroundColor, paddingTop: desktopImg || isMobile ? 40 : 0, paddingBottom: desktopImg || isMobile ? 40 : 0, display: 'flex', justifyContent: 'center',}}>
			<Grid padded style={{maxWidth: 1200}} stackable>
				<Grid.Row columns={2}>

					{!leftToRight &&
						<Grid.Column width={desktopImg ? 10 : 7}>
							<Image src={img} style={{maxHeight: 700,}} />
						</Grid.Column>
					}

					<Grid.Column width={desktopImg ? 6 : 9}>
						<div style={{height: '100%', display: 'flex', justifyContent: 'center',alignItems: 'center'}}>
							<div style={{maxWidth: 300, textAlign: isMobile || leftToRight ? 'left' : 'left'}}>
							<div style={{fontSize: '1.8em', lineHeight: 1.3, fontWeight: 'bold', marginBottom: 20}}>
								{title}
							</div>
							<div style={{ fontSize: '1.2em', color: colors.darkGray, lineHeight: 1.4, whiteSpace: 'pre-line'}}>
								{body}
							</div>
							</div>
						</div>
					</Grid.Column>

					{leftToRight &&
						<Grid.Column width={desktopImg ? 10 : 7}>
							<div>
								<Image src={img} style={{maxHeight: 700}}/>
							</div>
						</Grid.Column>
					}
				</Grid.Row>
			</Grid>
		</div>
	);
}
export default withRouter(HomePage);