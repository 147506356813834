import { Outlet } from "react-router";
import { Link } from "react-router-dom";
import { Icon, Image } from "semantic-ui-react";
import colors from "../utils/colors";
import fonts from "../utils/fonts";
import logo from '../content/calendar-512.png';
import {isBrowser} from 'react-device-detect';
import { useState } from "react";

export default function NavBar(props)
{
	const { 
		loggedIn,
	} = props;

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	window.addEventListener('resize', () => 
	{
		setWindowWidth(window.innerWidth);
	});

	const condense = windowWidth < 700;

	return (
		<div 
			style={{
				width: '100%', 
				display: 'flex',
				backgroundColor: colors.pageBackground,
				padding: 20,
				color: colors.white,
				fontSize: '1.4em',
			}}
		>
			
			<div 
				style={{
					marginLeft: 0, 
					width: '100%', 
					color: colors.lightGray
			}}>
				<Link to='/'>
					<div 
						style={{
							letterSpacing: 2,
							display: 'flex',
							color: colors.black,
						}}
					>
						<Image src={logo} style={{width: 35, height: 35, marginRight: 3, marginTop: -7}}/>
						Scheduly
					</div>
				</Link>
			</div>

			<Link to={'/plans'}>
				<div 
					style={{
						display: 'flex', 
						cursor: 'pointer', 
						color: colors.black,
						padding: 5,
						paddingLeft: 20,
						textAlign: 'center',
						marginTop: -5,
						borderRadius: 5,
						width: condense ? 90 : 190
					}} 
				>
					<div 
						style={{
							fontStyle: fonts.normal,  
							fontWeight: 500
						}}
					>
						 {condense ? 'Plans': 'Plans & Pricing'}
					</div>
				</div>
			</Link>

			<Link to={'/plans'}>
				<div 
					style={{
						display: 'flex', 
						cursor: 'pointer', 
						color: colors.white,
						backgroundColor: colors.accent,
						padding: 5,
						paddingRight: 20,
						paddingLeft: 20,
						textAlign: 'center',
						marginTop: -5,
						borderRadius: 5,
						marginRight: 20,
						width: condense ? 80 : 130
					}} 
				>
					<div 
						style={{
							// fontSize: 13, 
							fontStyle: fonts.normal,  
							fontWeight: 'bold'
						}}
					>
						 {condense ? 'Trial': 'Free Trial'}
					</div>
				</div>
			</Link>

			<Link to={loggedIn ? '/account' : '/login'}>
				<div 
					style={{
						display: 'flex', 
						cursor: 'pointer', 
						color: colors.black,
					}} 
				>
					{isBrowser &&
						<div 
							style={{
								fontSize: 14, 
								fontStyle: fonts.normal,  
								marginRight: 8,
								minWidth: loggedIn ? 80 : 35,
							}}
						>
							{loggedIn ? 'My Account' : 'Login'}
						</div>
					}
					{<Icon name='user outline' style={{width: '100%', fontSize: '0.9em'}}/>}
				</div>
			</Link>

			<Outlet/>
		</div>
	);
}
