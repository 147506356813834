
import colors from "../utils/colors";

export default function PageHeader(props)
{
	const { 
		text,
		style
	} = props;

	var allStyles = { 
		fontSize: '2em', 
		color: colors.black,
		fontWeight: 600
	};

	if (style)
	{
		Object.keys(style).forEach((key) => {
			allStyles[key] = style[key];
		});
	}

	return (
		<div style={allStyles}>{text?.toUpperCase()}</div>
	)
}