import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter, Routes, Route,  Link, Navigate } from 'react-router-dom';
import {Sidebar, Segment, Menu, Icon} from 'semantic-ui-react';
import NavBar from './components/NavBar';
import LoginPage from './pages/LoginPage';
import firebase from './utils/firebase';
import config from './utils/config';
import AccountsPage from './pages/AccountsPage';
import CheckoutPage from './pages/CheckoutPage';
import HomePage from './pages/HomePage';
import Footer from './components/Footer';
import PlansPage from './pages/PlansPage';
import Page from './components/Page';
import ReactGA from "react-ga4";

ReactGA.initialize(config.analyticsMeasurementId);

const signedInTabs = [
	{
		route: '/account',
		// icon: 'user circle',
		label: 'My Account',
		mobile: true
	},
  {
		route: '/plans',
		// icon: 'user circle',
		label: 'Pricing',
		mobile: true
	},
];

class App extends React.Component 
{
  constructor(props)
  {
    super(props);
    this.state = {
      authLoaded: false,
      loggedIn: false,
      user: null,
      permissions: null,
      userEmails: [],
      sidebarVisible: false,
    }
  }
  
  componentDidMount = () =>
  {
    firebase.auth().onAuthStateChanged(async (user) => 
    {
      config.debug && console.debug(`onAuthStateChanged: sign ${!user ? 'out': 'in'}`, user);
      this.setState({
        loggedIn: user !== null,
        authLoaded: true, 
        user: user, 
      });
    });
  }

  render() 
  {
    const { 
      loggedIn,
      authLoaded,
      user,
      sidebarVisible, 
    } = this.state;

    return (
      <BrowserRouter>
         
        <Sidebar.Pushable className="phsidebar" as={Segment}>

          <Sidebar 
            as={Menu}
            animation='overlay'
            // icon='labeled'
            onHide={() => this.setState({sidebarVisible: false})}
            vertical
            visible={sidebarVisible}
            width='thin'
          >
            {signedInTabs?.map((tab, idx) => {

            if (tab.route === '/account' && !loggedIn) return null;

            return (
              <Menu.Item key={idx} as={Link} to={tab.route} onClick={() => this.setState({sidebarVisible: false})}>
                <Icon name={tab.icon} />
                {tab.label}
              </Menu.Item>
            )
            })}
          </Sidebar>

          <Sidebar.Pusher>

            <div style={{backgroundColor: 'white'}}>
              
              <NavBar 
                loggedIn={loggedIn} 
                setSideBar={() => this.setState({sidebarVisible: !this.state.sidebarVisible})}
              />

              <Routes>
                <Route index element={<HomePage user={user} />} />
                <Route path='account' element={<PrivateRoute authLoaded={authLoaded} loggedIn={loggedIn} page={<AccountsPage user={user}/>}/>} />
                <Route path='plans' element={<PlansPage {...this.state}/>} />
                <Route path='checkout' element={<PrivateRoute authLoaded={authLoaded} loggedIn={loggedIn} page={<CheckoutPage user={user}/>}/>} />
                <Route path='login' element={<PublicRoute authLoaded={authLoaded} loggedIn={loggedIn} page={<LoginPage />}/>} />
                <Route path='*' element={<HomePage user={user} />} />

              </Routes>
              <Footer />
            </div>

          </Sidebar.Pusher>

        </Sidebar.Pushable>
        
      </BrowserRouter>
    );
  }
}

const PrivateRoute = (props) => 
{
  const { authLoaded,loggedIn,page } = props;

  var prevPathname = window.location.pathname;
  var prevSearch = window.location.search;

  return (
    authLoaded
      ? loggedIn 
        ? page 
        : <Navigate to='/login' state={{prevPathname, prevSearch}}/> 
      : <Page loading={true} />);
};

const PublicRoute = (props) => 
{
  const { authLoaded, loggedIn, page } = props;
  
  const Page = () => page;

  return authLoaded && loggedIn ? <Navigate to='/'/> : <Page/> ;
};

ReactDOM.render(<App />, document.getElementById('root'));
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
